.questionnaire-container {
    background-color: transparent;
    padding: 0;
    max-width: none;
    margin: 0;
}
  
.questionnaire-container h3 {
    font-size: 36px;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 30px;
    color: var(--secondary-color);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 15px;
}
  
.questionnaire-container p {
    font-size: 18px;
    line-height: 1.8;
    color: var(--text-color);
    margin-bottom: 30px;
}
  
.questionnaire-container label {
    display: block;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 12px;
}
  
.questionnaire-container input {
    width: 100%;
    padding: 15px 20px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
    background-color: white;
}
  
.questionnaire-container input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}
  
.error-message {
    background-color: #FEE2E2;
    border: 1px solid #F87171;
    color: #B91C1C;
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 30px;
    font-size: 16px;
}
  
.submit-button {
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    margin-top: 20px;
}
  
.submit-button:hover {
    background-color: #2563EB;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}
  
.submit-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.3);
}

@media (max-width: 768px) {
    .questionnaire-container h3 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    
    .questionnaire-container p {
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .questionnaire-container label {
        font-size: 16px;
        margin-bottom: 8px;
    }
    
    .questionnaire-container input,
    .submit-button {
        padding: 12px 20px;
    }
}
